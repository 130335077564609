.all-user-filter-search-area.mb-4.jhgdbjksldjfhvbnmcknjfb {
  justify-content: space-between;
  h4 {
    margin-bottom: 0;
  }
}
.input-checkbom-item-wrap {
  display: flex;
  justify-content: space-between;
}
.school-general-info-main-wrap {
  background: #fff;
  margin-top: 25px;
  padding: 40px;
  border-radius: 10px;
  .school-top-header-location {
    margin-bottom: 25px;
    img {
      width: 100%;
    }
  }
}
.school-survay-top-wrap {
  width: 600px;
  margin: auto;
  .step-item {
    position: relative;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      width: 140%;
      height: 1px;
      left: 70%;
      background: #caeaca;
      top: 40%;
    }
    &:last-child:after {
      display: none;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      color: #4eb94d4d;
      font-style: italic;
      background: #fff;
    }
    input[type="checkbox"]:checked + label {
      color: #fff !important;
      font-style: normal;
      background-color: #316afb;
      font-size: 24px;
    }

    input[type="checkbox"]:checked + label.step-survay2 {
      color: #fff !important;
      font-style: normal;
      background-color: #4eb94d;
      font-size: 24px;
    }
    label {
      width: 40px;
      height: 40px;
      border: 1px solid #4eb94d;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      border-radius: 10px;
      position: relative;
      z-index: 99;
    }
    span {
      font-size: 13px;
    }
  }
  .single-data-of-school {
    margin-bottom: 25px;
    input {
      height: 40px;
      margin-top: 4px;
      border: 1px solid #ddd;
      padding: 5px 10px;
      border-radius: 5px;
      outline: none;
    }
  }

  .single-data-of-school > * {
    display: block;
    width: 100%;
  }
}
.create-new-format-btn-area-wrap.jytrdcvbjhgf {
  justify-content: unset;
}
.school-general-info-main-wrap img {
  width: 100%;
}

.single-select-field {
  margin-top: 7px;
  margin-bottom: 7px;
}
.to-date-form > input.form-control {
  height: 38px;
}

.all-user-inner-wrap {
  .filter-box.ghigbrn > div > * {
    height: 30px !important;
    min-height: 35px !important;
  }
}
.react-responsive-modal-modal.allInstituteModal {
  max-width: 1030px;
}
.react-responsive-modal-modal.allInstituteModal > * > * > div {
  display: flex;
  gap: 10px;
  font-size: 12px;
  label {
    width: 180px;
    text-align: right;
    font-weight: 600;
  }
}
.issue-list-popup-wrap > * > * > div {
  display: flex;
  gap: 10px;
  font-size: 12px;
  label {
    width: 180px;
    text-align: right;
    font-weight: 600;
  }
}
.user-header-top-single-item {
  height: 100%;
}
.react-responsive-modal-modal.add-division-Modal {
  min-width: 600px;
  min-height: 420px;
}
form.add-division-popup-select-data > * > * > * {
  background: #4eb94d !important;
}

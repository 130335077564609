.user-header-top-single-item {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.user-header-top-single-item.ujhgfvbncjhg {
  justify-content: center;
  text-align: center;
  .content > a {
    text-decoration: none;
    background: #4eb94d;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
  }
}
.all-user-filter-search-area {
  display: flex;
  justify-content: end;
  .all-user-inner-wrap {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
}
.all-user-main-wra-area {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
}
.user-header-top-main-area-wrap {
  margin-bottom: 25px;
  background: #ffff;
  padding: 25px;
  border-radius: 10px;
}
.empty-user-area {
  text-align: center;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-data-main-area-wrap > table > thead {
  background: #f8f9fa;
}
.all-user-filter-search-area {
  display: flex;
  justify-content: end;
}

.empty-user-area {
  text-align: center;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-data-main-area-wrap > table > tbody > tr {
  &:last-child {
    border-bottom: none;
  }
  border-bottom: 1px solid #dddddd70;
}
.user-data-main-area-wrap > table {
  vertical-align: middle;
  > tbody {
    tr {
      td {
        padding: 15px 15px;
        .react-switch-bg > * > svg {
          display: none !important;
        }
      }
    }
  }
  > thead {
    background: #f8f9fa;
    tr {
      th {
        padding: 13px;
      }
    }
  }
}

.create-new-format-btn-area-wrap {
  display: flex;
  justify-content: end;
  button {
    text-align: right;
    display: inline-block;
    background: #4eb94d;
    color: #fff;
    padding: 10px 30px;
  }
}
.add-user-main-wrap {
  width: 991px;
  margin: 20px auto;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
}

.user-data-input-area-uygfvbhjuihyghjnk {
  text-align: center;
  input {
    display: none;
  }
  label {
    cursor: pointer;
    img {
      display: inline-block;
      margin: auto;
    }
  }
}

.all-user-main-wra-area {
  .user-data-main-area-wrap {
    span.status-deactive {
      background: #f46a6a33;
      color: #f46a6a;
      border-radius: 10px;
      padding: 8px 20px;
      width: 120px;
      display: inline-block;
      text-align: center;
    }
    span.status-active {
      background: #c3efdf;
      color: #34c38f;
      border-radius: 10px;
      padding: 8px 20px;
      width: 120px;
      display: inline-block;
      text-align: center;
    }
    a.user-set-routs-link-btn {
      background: #4eb94d;
      width: 120px;
      display: inline-block;
      text-align: center;
      padding: 8px 20px;
      border-radius: 25px;
      text-decoration: none;
      color: #fff;
    }
  }
}

.select-routs-main-wrap-area {
  display: flex;
  justify-content: center;
  .select-routs-main-wrap-inner-area {
    width: 600px;
    background: #fff;
    box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
    min-height: 400px;
    border-radius: 10px;
    margin-top: 30px;
  }
}
.header-topbar-right-content-wrap-left-area.set-routes {
  padding: 30px;
  .search-box-header-top-wrap {
    width: 100%;
    margin-top: 30px;
    background: #fff;
    border: 1px solid #ced4da;
  }
}

.set-routes-data-view-area {
  padding: 30px;
  h6 {
    margin-bottom: 20px;
  }
  table {
    thead {
      background: #f8f9fa;
    }
    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
        border-bottom: 1px solid #eff2f7;
        td {
          padding: 15px 10px;
          button {
            border: none;
            background: none;
          }
        }
      }
    }
  }
}
a.user-set-routs-link-btn.survay-page {
  width: unset !important;
}
.all-user-main-wra-area {
  .btn-check:focus + .btn-warning,
  .btn-warning:focus,
  .btn-warning:hover {
    border: 1px solid transparent;
  }
}
.issue-list-button-main-wrap {
  display: flex;
}

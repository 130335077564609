.footer-inner-area-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 245px;
  position: absolute;
  left: 0;
  bottom: -17px;
}

@media screen and (max-width: 1200px) {
  .footer-inner-area-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    margin: auto;
    padding-left: 0px;
    position: relative;
    margin-bottom: 30px;
  }
}

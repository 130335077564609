.profile-view-left-area {
  box-shadow: 0px 0px 5px 1px #ddddddab;
  padding: 25px;
  border-radius: 10px;
  background: #fff;
  .profile-picture-area button {
    display: block;
  }
  .profile-picture-area img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 10px;
  }

  .profile-input-image-area label {
    font-size: 40px;
  }

  .profile-input-image-area {
    position: relative;
  }
  .profile-input-image-area {
    top: -25px;
    right: -70px;
    position: relative;
    width: 30px;
    height: 30px;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}
.profile-single-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.profile-view-right-area {
  box-shadow: 0px 0px 5px 1px #ddddddab;
  padding: 25px;
  border-radius: 10px;
  height: 100%;
  background: #fff;
  span.profile-name {
    width: 130px;
  }
  span.profile-dec {
    display: flex;
    gap: 10px;
  }
}
.sopport-officer-wrap {
  box-shadow: 0px 0px 10px 0px #ddd;
  margin-top: 30px;
  padding: 30px;
  border-radius: 10px;
  background: #fff;
}
.support-officer-single-item img {
  width: 100px;
  height: 100px;
  display: block;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  margin-bottom: 15px;
  background-color: #dddddd60;
}
.support-officer-single-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.profile-picture-area > * {
  font-size: 14px;
  padding-left: 0;
}
.profile-picture-area > h5 {
  font-size: 22px;
}
.profile-picture-area > h6 {
  font-size: 12px;
  color: gray;
}
.form-control[type="file"] {
  padding: 6px;
}
.form-password-group-show-hide {
  position: relative;
  & > span {
    position: absolute;
    bottom: 8px;
    right: 15px;
  }
}
.input-field-login-email.password.form-password-group-show-hide span {
  bottom: 15px;
}

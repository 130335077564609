.single-attend-persion {
  background: #fff;
  text-align: center;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0 #e9ecef70;
  margin-bottom: 25px;
  img {
    margin-bottom: 10px;
  }
  h5 {
    margin-bottom: 0;
  }
  button {
    border: none;
    background: #4eb94d;
    color: #fff;
    padding: 10px 30px;
    border-radius: 10px;
  }
}

.react-responsive-modal-modal {
  border-radius: 10px;
  padding: 50px;
  .attendence-modal-data-view-vbjhgbn {
    .attendance-profile-view-image-area {
      display: flex;
      justify-content: center;
      gap: 15px;
      margin-bottom: 20px;
      align-items: center;
      span {
        h6 {
          margin-bottom: 0px;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
    width: 500px;
    table {
      thead {
        th {
          width: 50%;
        }
      }
    }
  }
}
.issue_modal{
  padding-top:0px !important;
}
